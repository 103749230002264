<template>
  <el-select
    class="select-dict"
    :style="{ width: width }"
    size="small"
    v-model="dictValue"
    :loading="isLoading"
    filterable
    remote
    :remote-method="getList"
    placeholder="请选择"
    clearable
    :multiple="multiple"
  >
    <el-option v-for="item in list" :label="item.name" :value="item.value" :key="item.value"></el-option>
  </el-select>
</template>

<script>
import { getDictEnumListAPI } from '@/enum/dict.js'
export default {
  name: 'SelectDict',

  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    dictCode: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '500px'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dictValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      // let params = {
      //   page: 1,
      //   pageSize: 20,
      //   name: query
      // }
      let res = await getDictEnumListAPI(this.dictCode)
      this.list = res.filter(item  => item.value!='payAtOnce')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select /deep/ {
  .el-select__caret:first-child::before {
    content: '\e6e1';
  }
  .is-focus {
    .el-select__caret:first-child {
      transform: rotateZ(0deg);
    }
  }
}
</style>
